@import "../assets/sass/variables";
.warning-icon {
  svg {
    width: 30px;
    height: 30px;
    color: $primaryColor;
  }
}
p {
  font-size: 14px;
}


.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4743db !important;
  border-color: #4743db !important;
}

.ant-select-selector {
  cursor: pointer;
}