@import "../../assets/sass/variables";

.document-page-container {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 20px;
  align-items: stretch;

  .ant-tabs-tab {
    color: $baseTextColor !important;
    font-weight: 600;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $darkBlue !important;
  }

  .drawer {
    position: absolute;
    top: 44%;
    left: 41.5%;
    transform: translate(-50%, -50%);
    z-index: 1;

    .arrow-icons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      border: 1px solid lightgray;
      width: 30px;
      height: 70px;
      border-radius: 30px;
      background-color: white;

      .right-arrow,
      .left-arrow {
        cursor: pointer;
        color: $textSecondaryColor;
      }
    }
  }

  .pdf-drawer {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .tab-drawer {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .point-tabs-container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
    overflow: auto;
    position: relative;
    background: #FBFBFB;

    .ant-tabs-nav-list {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-left: 30px;
    }
    .ant-tabs-ink-bar{
      width: 100%;
      background: $btnGradient;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
      color: $baseColor;
      font-family: Font-Semibold;
      border: none;
    }
  }

  .ant-tabs,
  .ant-tabs-content,
  .ant-tabs-tabpane-active {
    height: 100%;
  }

  .definition {
    margin-bottom: 10px;
  }

  .definition-title {
    color: $darkBlue;
    cursor: pointer;
  }

  .definition-textarea {
    width: 100%;
    height: 100px;
    border: 1px solid black;
    margin-top: 10px;
    padding: 5px;
    margin-right: 100px;
  }

  .hide {
    display: none;
  }
}

.full-tab {
  height: 100%;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  align-items: stretch;

  .tab-drawer {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .arrow-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border: 1px solid $textSecondaryColor;
    width: 30px;
    height: 70px;
    border-radius: 30px;

    .right-arrow,
    .left-arrow {
      cursor: pointer;
    }
  }
}

.full-pdf {
  grid-column: span 2;
}

.insights {
  padding: 20px;
}

.collapse {
  margin: 0px 15px;
  &-eligibility-pcp {
    .ant-collapse-item {
      border-radius: 6px;
      border: 1px solid $borderColor;
      background: $textPrimaryColor;
      padding: 10px;

      .label {
        color: black;
        font-weight: 500;
      }

      .precall-custom-short-collapse {
        padding: 5px 0;
      }
    }

    .ant-collapse {
      width: 100% !important;
    }
  }

  .precall-review-lg-collapse {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }

  .ant-collapse {
    width: 100%;
    background-color: $textPrimaryColor;
    border: none;
  }

  .ant-collapse-item {
    border-bottom: 1px solid $borderColor;
  }

  .ant-collapse-item-active {
    border-bottom: 1px solid #d3d6db;
  }

  .ant-collapse-header {
    color: $darkBlue !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    background-color: #FFFFFF;
    border-radius: 0px !important;
    padding: 8px;
  }

  .ant-collapse-content {
    border-radius: 0 !important;
    border-bottom: none !important;
    border-top: 1px solid $darkBlue
  }

  .ant-collapse-content-box {
    padding: 8px;
  }
}