.wrapper {
  background-color: white;

  .ant-table table {
    box-shadow: none;
    border: 0px;
    table-layout: fixed;  // Ensures table layout is flexible to handle content
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .table-actions {
    display: flex;
    justify-content: space-between;

    .search-document {
      width: 200px;
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;

      input {
        padding-right: 30px;
      }

      
    }

    .header-actions{
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }

  td {
    cursor: default;
  }

  .row {
    &-name {
      cursor: pointer;
      display: flex;
      gap: 7px;
      align-items: center;
      
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #3232A6;
      }
    }
  }

  thead {
    padding-right: 8px;
  }

  &-preview {
    color: #3232A6;
    display: flex;
    justify-content: center;

    &-icon {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .actions-tile {
    width: 100px;
  }

  &-page-count {
    text-align: center !important;
    width: 150px !important;
  }

  &-status {
    text-align: center !important;
    max-width: 180px !important;
  }

  &-small {
    text-align: center !important;
    max-width: 100px !important;
  }

  &-mid-size {
    text-align: center !important;
    max-width: 250px !important;
  }

  .table-doc-id {
    min-width: 30% !important;
    cursor: pointer;
  }

  .ant-table-tbody {
    max-height: calc(100vh - 290px) !important;
  }

  &__content {
    display: flex;
    gap: 10px;

  }

  .status {
    border: 1px solid #f5f9ff;
    border-radius: 3px;
    box-shadow: 0 2px 15px #0000000d;
    display: inline-block;
    font-family: Font-Medium, sans-serif;
    font-size: 14px;
    padding: 6px 10px;
  
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .table-actions {
    display: flex;
    justify-content: space-between;

    .search-document {
      width: 200px;
      display: flex;
      align-items: center;
      gap: 10px;

      input {
        padding-right: 30px;
      }
    }

    .header-actions {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }

  td {
    cursor: default;
  }

  .row-name {
    cursor: pointer;
    display: flex;
    gap: 7px;
    align-items: center;
  }

  .actions-tile {
    width: 100px;
  }

  .ant-table-tbody {
    max-height: calc(100vh - 290px) !important;
  }

  // Column widths
  .confidenceScore, .pageRange {
    width: 15%;
    max-width: 120px;
    min-width: 80px;
    text-align: center;
  }

  // Table overflow handling
  .ant-table-wrapper {
    overflow-x: auto;
    width: 100%;
  }
}

.split-summary {
  &__actions {
    justify-content: end;
    align-items: center;
    gap: 5px;
    display: flex;

    .icons {
      font-size: 26px;
      gap: 5px;
      display: flex;

      .button {
        border: none;
      }

      .icon {
        cursor: pointer;

        &-approve {
          color: rgb(0, 158, 0);
        }

        &-reject {
          color: rgb(239, 34, 34);
        }

        &:hover {
          filter: brightness(70%);
        }

        &.approved {
          background-color: rgb(0, 158, 0);
          color: white;
          border-radius: 50%;
        }

        &.not-approved {
          opacity: 50%;
        }

        &.not-rejected {
          opacity: 50%;
        }

        &.rejected {
          background-color: rgb(239, 34, 34);
          color: white;
          border-radius: 50%;
        }
      }
    }
  }
}

// Add dynamic column resizing
.confidenceScore, .pageRange {
  width: 10%;  // Use percentage widths to make it more flexible
  max-width: 120px; // Cap maximum width
  min-width: 100px; // Minimize it to fit content in small screens
  text-align: center;
}

.pageRange {
  width: 120px;
  text-align: center !important;
}


@media (max-width: 1024px) {
  .ant-table .ant-table-thead th,
  .ant-table .ant-table-tbody td {
    font-size: 14px; // Reduce font size for medium screens
  }

  .confidenceScore, .pageRange {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .ant-table .ant-table-thead th,
  .ant-table .ant-table-tbody td {
    font-size: 12px; // Further reduce font size
    padding: 8px;
  }

  .confidenceScore, .pageRange {
    font-size: 12px;
    width: 20%; // Adjust width for smaller screens
  }
}

@media (max-width: 480px) {
  .ant-table .ant-table-thead th,
  .ant-table .ant-table-tbody td {
    font-size: 10px; // Smallest font size for very small screens or extreme zoom
    padding: 6px;
  }

  .table-container {
    overflow-x: scroll; // Enable horizontal scrolling on very small screens
  }

  .confidenceScore, .pageRange {
    font-size: 10px;
    width: 30%;
  }
}
