.drawer-wrapper .StartTime {
    width: 38% !important; 
  }
  
  .drawer-wrapper .endTime {
    width: 38% !important; 
  }


  
.start-time-column {
  width: 21% !important; 
}

.end-time-column {
  width: 21% !important; 
}

.processing-time-column {
  width: 18% !important; 
}

.drawer-content{
  display: flex;
  flex-direction: column;
  gap: 15px;
  .table-section{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 15px;
  }
}
.text-wrapper {
  word-break: break-word;  
  white-space: normal;
  font-size: 12px;
}

.drawer-wrapper .ant-table-cell {
  font-size: 12px; 
}
