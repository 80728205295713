@import '../../../assets/sass/variables';

.comments {
  .section-details {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background-color: #f7f7f7;
      border-bottom: 1px solid #e8e8e8;
      margin: 10px;
      border-radius: 10px;
      .section-details-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
  
        strong {
          font-size: 14px;
          color: #555;
        }
  
        span {
          font-size: 16px;
          color: #000;
        }
      }
    }
  &>h4 {
    margin-bottom: 10px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 10px;
  }

  .single-comment {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 10px;

    .heading {
      display: flex;
      justify-content: space-between;
      gap: 0;
    }

    .date-time {
      color: $textSecondaryColor;
      font-size: 11px;
    }

    .content {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      align-items: flex-start;
      margin-top: 5px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 10px;

      &>* {
        cursor: pointer;

        path {
          fill: $darkBlue
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid lightgrey;
    }
  }

  textarea {
    width: 100% !important;
  }

  .section-header {
    padding: 10px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e8e8e8;

    h1 {
      margin: 0;
      font-size: 16px;
      color: #333;
    }
  }

  .comments-container {
    display: flex;
    flex-direction: column;
    height: 500px;
    position: relative;

    .comments-content {
      flex-grow: 1;
      overflow-y: auto;
      padding: 20px;
    }

    .new-comment-container,
    .edit-comment-container {
      display: flex;
      flex-direction: column;
      bottom: 0;
      width: 100%;
      background: #fff;
      padding: 20px;
      box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
      
      textarea {
        margin-bottom: 10px;
      }
      .comment-actions {
        display: flex;
        gap: 10px;
      }
      .comment-actions,
      .add-btn {
        align-self: flex-end;
      }
    }
  }
}