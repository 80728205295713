@use "../../assets/sass/variables";

.e {
  &-pdf {
    &.e-content {
      padding: 0px 1.625rem 1.625rem 1.625rem;
      border-left: 1px solid variables.$borderColor;
      border-radius: 6px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    canvas {
      margin: 0 auto;
      max-height: 77vh !important;
    }

    &-pagination {
      margin: 1.125rem 0;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.125rem;
      gap: 1.75rem;
      display: flex;

      .expand-icon {
        font-size: 16px;
        justify-self: flex-end;
        margin-bottom: 10px;
        &:hover {
          color: variables.$darkBlue;
        }
      }

      &-btn {
        &.ant-btn {
          border: 1px solid #888c8f;
          border-radius: 16px;
          min-width: 84px;
          background-color: transparent;
          color: variables.$baseColor;

          &[disabled] {
            background-color: transparent;
            color: rgba(255, 255, 255, 0.25);
          }
        }
      }
    }

    .expanded {
      overflow: auto;
      height: 80vh;
      canvas {
        max-height: 100% !important;
      }
    }
  }
}

.pdf-viewer {
  background: #FBFBFB;
  position: relative;
  min-width: 500px;

  &-closeicon {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 16px;
    cursor: pointer;
  }

  .ant-progress {
    margin: 11px;
    width: 94%;
  }
}