@use "../../assets/sass/variables";

.back-icon {
  a {
    color: variables.$baseTextColor;
    display: flex;
    align-items: center;

    &:hover {
      color: variables.$darkBlue !important;
    }
  }

  svg {
    padding: 5px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: variables.$borderColor;
    font-size: 20px;
  }

}